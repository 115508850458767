<!--파트너사 관리 페이지 메인-->
<template>
	<div>
		<!--검색 조건 카드 시작-->
		<searchCard>
			<div class="inline-block">
				<h6>계약일</h6>
				<div class="flex">
					<b-form-radio v-model="opt.period" name="searchRadioPeriod" value="전체기간">
						<span class="mr-6">전체기간</span>
					</b-form-radio>
					<b-form-radio v-model="opt.period" name="searchRadioPeriod" value="" />
					<div class="flex -mt-1.5">
						<datePicker class="w-2/5" model="opt.startDate" />
						<span class="p-2 lh-6">~</span>
						<datePicker class="w-2/5" model="opt.endDate" />
					</div>
				</div>
			</div>
			<div class="inline-block">
				<h6>패키지 정보</h6>
				<div class="flex">
					<b-form-radio
						v-for="v in packageOpts"
						class="mr-6"
						v-model="opt.contractPackage"
						name="searchPackage"
						:value="v.value"
					>
						{{ v.label }}
					</b-form-radio>
				</div>
			</div>
			<div v-if="$acl.get[0] == rule.accounting" class="inline-block ml-30">
				<h6>정산</h6>
				<div class="flex">
					<vSelect class="w-40" v-model="account.y" :clearable="false" :options="account.yOpts" />
					<p class="p-1.5 lh-6">년</p>
					<vSelect class="w-40 ml-4" v-model="account.m" :clearable="false" :options="account.mOpts" />
					<p class="p-1.5 lh-6">월</p>
					<b-button class="ml-4" @click="accountingPartner({ storeSeq: 84 })" variant="purple"
						>(임시)소셜빈 정산
					</b-button>
					<b-button class="ml-4" @click="accountingPartner({ storeSeq: -1 })" variant="orange"
					>(임시)소셜빈 외 정산
					</b-button>
				</div>
			</div>
			<div class="flex w-1/1 mt-2 relative">
				<vSelect class="w-2/12" v-model="opt.searchKeyName" :clearable="false" :options="searchKeyOpts" />
				<b-form-input
					class="w-1/4 ml-4"
					v-model.trim="opt.searchKeyword"
					name="keyword"
					placeholder="검색어"
					@keyup.enter="search"
				/>
				<b-button class="w-1/12 ml-2" @click="search">검색</b-button>
				<div class="absolute right-0">
					<b-button class="mr-4" @click="excelExportAllPackage">전체 패키지 정보 다운로드 요청</b-button>
					<b-button @click="excelExport">엑셀 다운로드 요청</b-button>
				</div>
			</div>
		</searchCard>

		<!--검색 결과 리스트 시작-->
		<b-card>
			<tb
				class="noSelectedEffect"
				@changePage="changePage"
				@click="selectItem"
				:inf="inf"
				:res="res"
				selectable
			/>
		</b-card>
		<dealCalendarPopup :pr="ths" cName="dealCalendarPopup" />
	</div>
</template>

<script>
import dealCalendarPopup from 'pages/dealCalendarPopup'

const now = new Date(),
	searchKeyOpts = [
		{ label: '파트너사 통합검색', value: 'all' },
		{ label: '사업자명', value: 'companyName' },
		{ label: '총괄담당자', value: 'hottPicName' },
		{ label: '총괄담당자 연락처1', value: 'hottPicPhone1' },
		{ label: '총괄담당자 연락처2', value: 'hottPicPhone2' },
		{ label: '총괄담당자 이메일', value: 'hottPicEmail' },
		{ label: '계약담당자', value: 'contractPicName' },
	],
	defOpt = {
		period: '전체기간',

		startDate: new Date(now.getFullYear(), now.getMonth(), now.getDate() - 30),
		endDate: now,
		contractPackage: 'all',

		searchKeyName: searchKeyOpts[0],
		searchKeyword: '',
	}
//검색조건 기본값. 검색 조건 초기화를 위해 사용 opt로 치환됨

export default {
	name: 'keep',
	components: { dealCalendarPopup },
	data() {
		return {
			searchKeyOpts,
			packageOpts: [
				{ label: '전체', value: 'all' },
				{ label: '테스트', value: 'test' },
				{ label: '베이직', value: 'basic' },
				{ label: '프리미엄', value: 'premium' },
				{ label: '기본', value: 'default' },
			],

			opt: { ...defOpt },
			defOpt,
			lastOpt: {},
			isSetResult: false,
			res: {},

			rule,
			account: {
				yOpts: [],
				mOpts: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12],
				y: '',
				m: '',
			},
		}
	},
	methods: {
		search(operation = 'LIKE') {
			//검색 시작
			//뷰/테마 설정인지 개발자 설정인진 모르겠는데 기본값 설정을 해도 isTrusted 객체로 온다? 여기선 클릭 이벤트가 넘어오네..
			if (typeof operation != 'string') operation = 'LIKE'
			const opt = this.opt,
				startDate = getDateValue(opt.startDate),
				endDate = getDateValue(opt.endDate),
				searchKeyName = getDropdownValue(opt.searchKeyName),
				searchVal = opt.searchKeyword

			let data = { paging: getPaging(), isExcel: 0, contractPackage: opt.contractPackage },
				//선택 조건에 없는 필수값들 입력
				error = []

			//유효성 검사 및 서버에 보낼 데이터 채우기
			if (!opt.period && startDate && endDate) {
				if (startDate > endDate) error.push('작성일의 시작일이 종료일보다 큽니다.')
				else {
					data.startDate = startDate.substr(0, 10).replace(/-/g, '')
					data.endDate = endDate.substr(0, 10).replace(/-/g, '')
				}
			}

			if (searchKeyName && searchVal) {
				let searchMap, andOr
				if (searchKeyName == 'all') {
					//파트너사 통합 검색의 경우
					searchMap = [
						'companyName',
						'hottPicName',
						'hottPicPhone1',
						'hottPicPhone2',
						'hottPicEmail',
						'contractPicName',
					]
					andOr = 'or'
				} else {
					//그 이외 검색 조건의 경우
					searchMap = [searchKeyName]
					andOr = 'and'
				}
				data.searchKey = searchMap.map(v => {
					return {
						fieldName: v,
						andOr,
						operation,
						value: searchVal,
					}
				})
			}
			if (error.length) {
				//유효성 검사에서 오류가 있을 경우 알럿
				alert.e(error)
				return false
			} else {
				if (Object.keys(this.lastOpt).length)
					//기존에 검색한 게 있을 경우 정렬 상태 유지
					data.orderBy = this.lastOpt.orderBy
				else data.orderBy = getOrder('storeContractDate')
				//첫 검색일 경우 정렬 상태 초기화

				//오류가 없으면 검색 조건 설정하고 1페이지로 설정
				this.lastOpt = data
				return this.changePage(1)
			}
		},
		changePage(page = 1, ex = false) {
			//마지막 검색 조건을 기준으로 페이지 변경
			this.lastOpt.paging.pageNo = page - 1
			return postApi('/partnerSelect/partnerList', addExcelData(this, ex)).then(res => {
				if (!ex) {
					//데이터가 바뀔 때마다 처음에 한 번만 포매팅이 필요
					const dateColumn = ['contractDate', 'regDt', 'requestDate', 'updateDt']
					res.list = res.list.map(v => {
						dateColumn.map(k => (v[k] = v[k] ? v[k].replace('T', ' ') : ''))
						v.contractDate = v.contractDate.substr(0, 10)

						v.storeContractDate = v.contractDate
						v.storeRegDt = v.regDt
						v.storeUpdateDt = v.updateDt

						v.productGroupInfo = `전체: ${v.productGroupTotalCount}\r\n판매중: ${v.productGroupSalesCount}`
						v.packageInfo = `테스트: ${v.productGroupTestPackageCount}\r\n베이직: ${v.productGroupBasicPackageCount}\r\n프리미엄: ${v.productGroupPremiumPackageCount}\r\n기본: ${v.productGroupDefaultPackageCount}`
						return v
					})

					this.res = res
				} else alert.excel()
			})
		},
		getExData() {
			const opt = this.lastOpt,
				ex = new excel()

			ex.date(opt.startDate, opt.endDate, '계약일')
			if (opt.contractPackage) ex.key('패키지 정보', getOptsLabel(this.packageOpts, opt.contractPackage))
			ex.search(opt.searchKey, this.searchKeyOpts)
			return ex
		},
		excelExport() {
			this.changePage(this.currentPage, this.getExData())
		},
		excelExportAllPackage() {
			postApi('api/backoffice/partnerSelect/partnerPackageInfo', {
				excelInfo: new excel(undefined, '전체 패키지 정보').get(),
			}).then(() => alert.excel())
		},
		accountingPartner({ storeSeq }) {
			const excelInfo = this.getExData().get(),
				account = this.account

			excelInfo.position += ' 정산 파일 다운로드'
			postApi('settlementSelect/downloadStoreSettlementList', {
				storeSeq,
				selectedMonth: account.y + ('0' + account.m).substr(-2),
				adminSeq: excelInfo.adminSeq,
				excelInfo,
			}).then(() => alert.excel())
		},
		selectItem(v) {
			page('partnerDetail', { storeSeq: v.storeSeq })
		},
		openCalendar(item) {
			this.dealCalendarPopup.open(item, false)
		},
	},
	computed: {
		inf() {
			const data = [
				{ title: 'No.', model: 'idx', size: 3 },
				{ title: '사업자명', key: 'comName', size: 18, notCenter: 1 },
				{ title: '총괄담당자', key: 'hottPicName', size: 5 },
				{ title: '총괄담당자 연락처1', key: 'hottPicPhone1', size: 6 },
				{ title: '총괄담당자 연락처2', key: 'hottPicPhone2', size: 6 },
				{ title: '총괄담당자 이메일', key: 'hottPicEmail', size: 12, notCenter: 1 },
				{ title: '계약담당자', key: 'contractPicName', size: 5 },
				{ title: '계약일', key: 'storeContractDate', size: 8, sortable: 1 },
				{ title: '등록일', key: 'storeRegDt', size: 6, notCenter: 1, sortable: 1 },
				{
					title: '업데이트일',
					key: 'storeUpdateDt',
					size: 6,
					notCenter: 1,
					sortable: 1,
				},
				{ title: '상품그룹', key: 'productGroupInfo', size: 6 },
				{ title: '패키지 정보', key: 'packageInfo', size: 6 },
				{
					title: '딜 캘린더',
					model: 'button',
					icon: 'calendar4-week',
					size: 3,
					func: this.openCalendar,
				},
				{
					title: '수정',
					model: 'button',
					icon: 'pencil-square',
					size: 3,
					func: item => {
						page('partnerAdd', { storeSeq: item.storeSeq })
						//this.templateComp.templatePopup.open(this.kind, true, item)
					},
				},
				{ title: '메모', model: 'memoBtn', domain: 'STORE', size: 3 },
			]

			if (this.$acl.get[0] == rule.accounting)
				data.push({
					title: '정산',
					model: 'button',
					size: 3,
					text: '정산',
					bVariant: 'no',
					class: 'text-primary',
					func: this.accountingPartner,
				})
			return data
		},
	},
	created() {
		if (this.$acl.get[0] == 'ROLE_ACCOUNTING') {
			const account = this.account,
				y = now.getFullYear(),
				m = now.getMonth() + 1

			account.y = y
			account.m = m
			for (let i = 2019; i <= y; i++) account.yOpts.push(i)
			//파트너사 정산 기본값 초기화 및 셀렉트 리스트 입력
		}
	},
	mounted() {
		this.search()
	},
}
</script>
